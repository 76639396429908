@media (max-width: 1200px) {
header.side-header {
    left: -220px;
}
.main-content.wide-content {
    padding-left: 45px;
}
.main-content {
    padding-left: 45px;
}
footer.wide-footer {
    padding-left: 45px;
}
footer {
    padding-left: 45px;
}
header.side-header.slide-menu {
    left: 0;
}
header.side-header.sticky {
    top: 0;
}
.top-bar {
    float: left;
    position: static;
    width: 100%;
}



}

@media (max-width:980px) {
.icon-section .icon-container {
    width: 33.334%;
}    
.quick-stats > .row > div {
    float: left;
    width: 25%;
}
.panel-setting .quick-stats > .row > div.col-md-4 {
    float: left;
    margin-top: 60px;
    text-align: center;
    width: 100%;
}
.panel-setting .total-sales-info {
    float: left;
    width: 100%;
}
.panel-setting .total-sales-info > h3::before {
    left: 50%;
    margin-left: -50px;
}
.logo {
    min-width: 170px;
    padding-left: 30px;
}
.top-bar form.search-form {
    margin-left: 20px;
    width: 170px;
}
.top-bar > ul > li {
    margin-left: 30px;
}
.heading-profile {
    text-align: center;
}
.breadcrumbs > ul {
    text-align: center;
}
.breadcrumbs > ul > li {
    display: inline-block;
    float: none;
}
.breadcrumbs > ul > li:last-child > a::before {
    display: none;
}
.top-bar-chart {
    text-align: center;
}
.top-bar-chart > .quick-report {
    display: inline-block;
    float: none;
    margin: 30px 30px 0;
}
.top-bar {
    float: left;
    position: static;
    width: 100%;
}
.main-content {
    padding-top: 40px;
    padding-bottom: 40px;
}
footer {
    float: left;
    position: static;
    width: 100%;
}
.mini-stats-sec .row > div {
    float: left;
    width: 50%;
}
.total-sales-info {
    width: auto;
}
.visitor-country {
    text-align: center;
}
.visitor-country > #vmap {
    display: inline-block;
    float: none;
    margin: 30px 0 0;
    width: 100%;
}
.visitor-country-list {
    display: inline-block;
    float: none;
    margin-top: 30px;
    text-align: left;
}
.widget-carousel {
    background-size: cover;
}
header.side-header.sticky {
    top: 0;
}
.billing-sec .field {
    width: 100%;
}
.our-team-area > div {
    float: left;
    width: 50%;
}
.filter-products > div {
    float: left;
    width: 50%;
}
.gallery-sec > .row > div {
    float: left;
    width: 50%;
}
.inbox-panel-head {
    text-align: center;
}
.inbox-panel-head > img {
    display: inline-block;
    float: none;
    margin: 0 0 20px;
}
.inbox-navigation {
    margin-bottom: 30px;
}
.creat-an-account {
    padding-left: 0;
    padding-top: 30px;
}
.account-form input[type="submit"] {
    margin: 0 !important;
}
.more-option {
    float: left;
    position: static;
    
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);

    width: 100%;
}
.account-form > form div.feild {
    float: left;
    width: 100%;
}
.account-form label {
 
   margin-bottom: 20px;
}
.account-detail > ul {
    margin-bottom: 30px;
}
.product-stats > ul {
    display: inline-block;
    float: none;
}



}

@media (max-width: 767px) {
.icon-section .icon-container {
    width: 50%;
}       
.top-bar {
    text-align: center;
}
header.side-header {
    top: 0;
}
header.side-header {
    z-index: 2147483646;
}
.menu-options.active {
    left: 190px;
    position: fixed;
    top: 12px;
    z-index: 2147483647;
}
.quick-links {
    display: inline-block;
    float: none;
    margin: 0 20px;
    padding-right: 0;
}
.top-bar > ul {
    display: inline-block;
    float: none;
}
.top-bar-chart > .quick-report {
    float: left;
    margin: 30px 0 0;
    padding: 0 30px;
    width: 50%;
}
.user-bar {
    padding: 0;
}
.user-thumb {
    float: left;
    margin: 0;
    padding-top: 0;
    position: static;
    text-align: center;
    
    -webkit-transform: translate(0px);
    -moz-transform: translate(0px);
    -ms-transform: translate(0px);
    -o-transform: translate(0px);
    transform: translate(0px);

    width: 100%;
}
.user-thumb > img {
    display: inline-block;
    float: none;
    padding: 0;
    width: 110px;
}
.user-bar > .social-btns {
    float: left;
    margin-bottom: 50px;
    margin-top: 20px;
    text-align: center;
    width: 100%;
}
.user-bar > ul {
    float: left;
    text-align: center;
    width: 100%;
}
.user-bar > ul > li {
    display: inline-block;
    float: none;
    padding: 0 15px;
}
.user-bar {
    text-align: center;
}
.user-bar > a {
    display: inline-block;
    float: none;
    margin: 30px 0;
}
.profile-all {
    margin: 0;
}
.acount-sec {
    padding: 60px 15px;
}
.invoice {
    min-width: 1060px;
}
.invoice-sec {
    overflow-x: scroll;
}
.account-top-bar {
    padding: 0 15px;
}
.quick-stats > .row > div {
    margin-bottom: 30px;
    width: 50%;
}
.toggle-content {
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;
}


}

@media (max-width: 480px)  {
.quick-stats > .row > div {
    margin-bottom: 30px;
    width: 50%;
}
.panel-setting {
    padding: 0 20px;
}
.top-bar > .logo {
    float: left;
    margin: 20px 0 3px;
    padding: 0 30px;
    width: 100%;
}
.top-bar form.search-form {
    display: inline-block;
    float: none;
    margin: 16px 0 0;
    padding: 0;
    width: 90%;
}
.top-bar > ul > li:first-child {
    margin: 0;
}
.top-bar > ul > li {
    margin-left: 23px;
}
.quick-links {
    padding-top: 0;
}
.heading-profile > h2 {
    font-size: 17px;
}
.breadcrumbs {
    margin-bottom: 0;
}
.main-content.wide-content {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
}
.main-content {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
}
.top-bar-chart > .quick-report {
    float: left;
    margin: 0 0 22px;
    padding: 0;
    width: 100%;
}
.mini-stats-sec .row > div {
    width: 100%;
}
.total-sales-sec {
    padding: 30px 20px;
    text-align: center;
}
.circle-chart {
    display: inline-block;
    float: none;
    margin: 0;
}
.total-sales-info {
    float: left;
    padding-top: 20px;
    width: 100%;
}
.total-sales-info > h3::before {
    left: 50%;
    margin-left: -50px;
}
.activity-sec {
    padding: 40px 20px;
}
.graph-details > ul > li {
    margin-top: 20px;
    width: 100%;
}
.graph-details > ul > li:first-child {
    margin: 0;
}
footer.wide-footer {
    padding: 20px 15px;
}
footer p {
    float: left;
    font-size: 11px;
    text-align: center;
    width: 100%;
}
footer ul {
    float: left;
    text-align: center;
    width: 100%;
}
footer ul li {
    display: inline-block;
    float: none;
}
.welcome-bar {
    padding: 20px;
}
.server-details {
    float: left;
    padding: 30px 20px;
    width: 100%;
}
#area-chart {
    display: inherit !important;
    float: left;
    margin-left: -16px;
    padding: 0 50px 30px 20px;
    width: 100%;
}
.task-managment {
    padding: 30px 20px;
}
.task-managment > ol > li > i {
    left: -35px;
}
.pieID.legend {
    padding-left: 15px;
}
.pie-chart-sec {
    padding: 30px 20px;
}
.revenew-browser {
    padding: 30px 20px;
}
.social-counter {
    padding: 30px 20px;
}
.social-widget > ul > li > i {
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    margin-right: 10px;
    width: 30px;
}
.social-widget > ul > li h5 {
    font-size: 14px;
}
.social-widget > ul > li span {
    margin-top: -5px;
}
.social-widget > span {
    font-size: 14px;
}
.visitor-country-list {
    padding: 0 20px 30px;
}
.tasks-progress {
    padding: 30px 20px;
}
.tasks-progress > ul > li {
    float: left;
    padding: 0 10px;
    width: 100%;
}

.user-device {
    font-size: 13px;
    height: 30px;
    line-height: 30px;
    width: 30px;
}

.activity-timeline::before {
    left: 14px;
}
.activity-timeline > li {
    padding-left: 39px;
}
.user-device::before {
    right: -8px;
}
.who-post-this > span {
    margin-right: 13px;
}
.widget-carousel > ul > li {
    padding: 0 20px;
}
.save-draft {
    padding: 30px 20px;
}
.recent-comment-sec {
    padding: 30px 20px;
}
.recent-comment {
    text-align: center;
}
.comments-avatar {
    display: inline-block;
    float: none;
    margin: 0 0 20px;
}
.comment-detail {
    float: left;
    width: 100%;
}
.admin-follow {
    padding: 30px 20px;
}
.admin-follow > ul > li {
    padding: 0 9px;
}
.our-clients-sec {
    padding: 30px 20px;
}
.chat-widget {
    padding: 30px 20px;
}
.twitter-feed {
    padding: 30px 20px;
}
.welcome-bar {
    text-align: center;
}
.welcome-bar > i {
    display: inline-block;
    float: none;
    margin: 0 0 10px;
}
.profile-banner-sec > ul {
    float: left;
    margin: 20px 0 14px;
    position: static;
    
    -webkit-transform: translate(0px);
    -moz-transform: translate(0px);
    -ms-transform: translate(0px);
    -o-transform: translate(0px);
    transform: translate(0px);

    width: 100%;
}
.post-content iframe {
    float: left;
    width: 100%;
}
.collapse-sec {
    padding: 30px 20px;
}
.panel-group .panel > .panel-collapse .panel-body {
    padding: 20px;
}
.our-team-area > div {
    float: left;
    width: 100%;
}
body .panel-content .our-team-area {
    float: left;
    padding: 0;
    width: 100%;
}
.product-filter {
    padding: 40px 20px;
}
.filter-products > div {
    float: left;
    padding: 0;
    width: 100%;
}
.countdown-sec {
    margin-top: 40px;
}
ul.countdown > li {
    margin-bottom: 12px;
}
.fancy-social {
    margin-top: 20px;
}
.fancy-social > span {
    font-size: 14px;
}
.commingsoon form.subscribtion {
    margin-top: 30px;
}
.commingsoon form.subscribtion > button {
    float: left;
    margin-top: 14px;
    width: 100%;
}
.contact-sec {
    padding: 30px 20px;
}
.gallery-sec > .row > div {
    float: left;
    width: 100%;
}
.message-list > li > p {
    float: left;
    margin-top: 6px;
    overflow: hidden;
    white-space: normal;
    width: 100%;
}
.message-list > li > a {
    margin-left: 15px;
}
.message-list > li > span.make-important {
    margin-right: 12px;
}
.compose-email-sec {
    padding: 0;
}
.form-elements-sec {
    padding: 30px 20px;
}
.step-form-sec {
    padding: 30px 20px;
}
.pie-responsive {
    padding: 30px 20px;
}
#TicketByDepartmentLegends {
    padding: 0 !important;
}
#TicketByDepartmentLegends li span.icon {
    margin-left: 0;
}
#TicketByDepartmentLegends li span.label {
    margin-left: 18px;
}
.mini-charts {
    padding: 30px 20px;
}
#vector-map {
    height: 300px !important;
}
.mockup img {
    width: 100%;
}
.error-sec > h2 {
    font-size: 66px;
}
.account-header-link > li {
    float: left;
    margin: 0;
    width: 100%;
}
.account-header-link > li > a {
    padding: 3px 0;
}
.account-header-link {
    margin-bottom: 20px;
}
.widget-title > h3 {
    margin-bottom: 12px;
}
.accordion > h3 {
    line-height: 22px;
    padding: 20px;
}
.account-detail > ul > li {
    float: left;
    text-align: center;
    width: 100%;
}
.account-detail > ul > li > h3 {
    float: left;
    width: 100%;
}
.account-detail > ul > li > p {
    padding: 0;
}
.more-option {
    margin-top: 30px;
    padding: 0;
    width: 99px;
}
.quick-links > ul > li .dialouge {
    right: 50%;
    
    -webkit-transform: translateX(50%);
    -moz-transform: translateX(50%);
    -ms-transform: translateX(50%);
    -o-transform: translateX(50%);
    transform: translateX(50%);

    width: 200px;
}
.quick-links > ul > li .dialouge::before {
    margin-right: -8px;
    right: 50%;
}
.icon-section .icon-container {
    width: 100%;
} 


}
@import './components/variables';

@import './components/loginForm';

html, 
body, 
.admin-details > i,
.breadcrumbs > ul > li > a,
.side-menus nav > ul > li > a,
footer li a
{
    font-size: $font-size * 1.1;
}

.admin-details h3, .widget-title > h3 {
    font-size: $font-size * 1.7;
}

.red-skin {
    background-color: $theme-color;
    border-color: $theme-color !important;
}

.admin-details > h3 {
    word-break: break-word;
}
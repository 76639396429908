.account-form > form div.feild > input[type="email"] {
    border: 1px solid #e6eaea;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px;
    float: left;
    font-size: 13px;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    padding: 13px 30px;
    width: 100%;
}